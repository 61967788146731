<template>
    <div>
        <v-menu
            v-if="fileData.type !== 'folder'"
            offset-x
            offset-overflow
            right
            open-on-hover
            :disabled="currentFileStatus === fileStatusTypes.STATUS_UNAVAILABLE"
            class="mb-5 ml-10"
            :close-delay="closeDelay"
        >
            <template v-slot:activator="{ on }">
                <div class="d-flex align-center" style="min-width:200px" v-on="on">
                    <v-icon v-if="currentFileStatus === fileStatusTypes.SAVED_AND_UNCHANGED" class="mr-1" color="success" small>fiber_manual_record</v-icon>
                    <v-icon v-else-if="currentFileStatus === fileStatusTypes.SAVED_AND_CHANGED" class="mr-1" color="info" small>fiber_manual_record</v-icon>
                    <v-icon v-else-if="currentFileStatus === fileStatusTypes.UNSAVED" class="mr-1" color="error" small>fiber_manual_record</v-icon>
                    <v-icon v-else-if="currentFileStatus === fileStatusTypes.STATUS_UNAVAILABLE" class="mr-1" color="primary" small>fiber_manual_record</v-icon>
                    <span v-if="relativeFileModificationDate($props.fileData.last_modified_timestamp) === $props.fileData.last_modified_timestamp"
                        >{{ $props.fileData.last_modified_timestamp | dateTimeToHuman
                        }}<span style="font-size:10px" class="primary--text font-weight-bold ml-1">UTC</span></span
                    >
                    <span v-else>{{ relativeFileModificationDate($props.fileData.last_modified_timestamp) }}</span>
                </div>
            </template>
            <v-card>
                <v-card-text class="ma-0 pa-0">
                    <v-alert
                        color="success"
                        border="left"
                        colored-border
                        icon="mdi-check-circle"
                        class="ma-0 primary--text"
                        max-width="350"
                        v-if="currentFileStatus === fileStatusTypes.SAVED_AND_UNCHANGED"
                    >
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Unmodified since last snapshot</span>
                            <span class="subtitle-2">This file was snapshotted, and no modifications were made since the last snapshot.</span>
                            <v-divider color="success" style="opacity: 0.22" class="mt-1"></v-divider>
                            <div class="d-flex justify-end">
                                <v-btn
                                    text
                                    small
                                    color="primary"
                                    class="caption font-weight-bold"
                                    @click="showFileVersions($props.fileData.fid, $props.fileData.short_id)"
                                >
                                    <v-icon class="mr-1" small>mdi-file-compare</v-icon>compare previous versions
                                </v-btn>
                            </div>
                        </div>
                    </v-alert>
                    <v-alert
                        color="info"
                        border="left"
                        colored-border
                        icon="info"
                        class="ma-0 primary--text"
                        max-width="350"
                        v-else-if="currentFileStatus === fileStatusTypes.SAVED_AND_CHANGED"
                    >
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Modified since last snapshot</span>
                            <span class="subtitle-2">This file was snapshotted, but it has been changed since the last snapshot.</span>
                            <v-divider color="secondary" style="opacity: 0.22" class="mt-1"></v-divider>
                            <div class="d-flex flex-wrap justify-end">
                                <v-btn
                                    text
                                    small
                                    color="primary"
                                    class="caption font-weight-bold"
                                    @click="showFileVersions($props.fileData.fid, $props.fileData.short_id)"
                                >
                                    <v-icon class="mr-1" small>mdi-file-compare</v-icon>compare previous versions
                                </v-btn>
                                <v-btn text small color="primary" class="caption font-weight-bold" @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)">
                                    <v-icon class="mr-1" small>mdi-camera-plus-outline</v-icon>quick snapshot
                                </v-btn>
                            </div>
                        </div>
                    </v-alert>
                    <v-alert
                        color="error"
                        border="left"
                        colored-border
                        icon="error"
                        class="ma-0 primary--text"
                        max-width="350"
                        v-else-if="currentFileStatus === fileStatusTypes.UNSAVED"
                    >
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Unsnapshotted file</span>
                            <span class="subtitle-2">This file hasn't been snapshotted yet.</span>
                            <v-divider color="error" style="opacity: 0.22" class="mt-1"></v-divider>
                            <div class="d-flex justify-end">
                                <v-btn text color="primary" class="caption font-weight-bold" @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)">
                                    <v-icon class="mr-1" small>mdi-camera-plus-outline</v-icon>quick snapshot
                                </v-btn>
                            </div>
                        </div>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-menu>
        <div style="min-width:200px" class="d-flex align-center" v-else>
            <v-icon class="mr-1" color="transparent" small>fiber_manual_record</v-icon>
            <span
                >{{ $props.fileData.last_modified_timestamp | dateTimeToHuman
                }}<span style="font-size:10px" class="primary--text font-weight-bold ml-1">UTC</span></span
            >
        </div>
    </div>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import { snapshotEnums } from '@/mixins/snapshot'
export default {
    name: 'FileStatus',
    mixins: [enumsData, createQuickAndDetailedSnapshots, snapshotEnums],
    props: {
        fileData: Object
    },
    data() {
        return {
            closeDelay: 100,
            fileStatusTypes: {
                SAVED_AND_UNCHANGED: 'SAVED_AND_UNCHANGED',
                SAVED_AND_CHANGED: 'SAVED_AND_CHANGED',
                UNSAVED: 'UNSAVED',
                STATUS_UNAVAILABLE: 'STATUS_UNAVAILABLE'
            }
        }
    },
    methods: {
        showFileVersions: function(fid, fileName) {
            this.$router.push({
                name: 'snapshot-file-diff',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    fid: this.$props.fileData.fid
                }
            })
        },
        relativeFileModificationDate: function(dateString) {
            const lastModified = new Date(dateString)
            const now = new Date()
            if (
                lastModified.getUTCFullYear() !== now.getUTCFullYear() ||
                lastModified.getUTCMonth() !== now.getUTCMonth() ||
                now.getUTCDate() - lastModified.getUTCDate() > 7
            ) {
                return dateString
            } else if (now.getUTCDate() - lastModified.getUTCDate() === 1) {
                return '1 day ago'
            } else if (now.getUTCDate() - lastModified.getUTCDate() > 1) {
                return `${now.getUTCDate() - lastModified.getUTCDate()} days ago`
            } else if (now.getUTCHours() - lastModified.getUTCHours() === 1) {
                return '1 hour ago'
            } else if (now.getUTCHours() - lastModified.getUTCHours() > 1) {
                return `${now.getUTCHours() - lastModified.getUTCHours()} hours ago`
            } else if (now.getUTCMinutes() - lastModified.getUTCMinutes() === 1) {
                return '1 minute ago'
            } else if (now.getUTCMinutes() - lastModified.getUTCMinutes() > 1) {
                return `${now.getUTCMinutes() - lastModified.getUTCMinutes()} minutes ago`
            } else if (now.getUTCSeconds() - lastModified.getUTCSeconds() === 1) {
                return '1 second ago'
            } else if (now.getUTCSeconds() - lastModified.getUTCSeconds() > 1) {
                return `${now.getUTCSeconds() - lastModified.getUTCSeconds()} seconds ago`
            } else {
                return dateString
            }
        }
    },
    computed: {
        currentFileStatus: function() {
            if (this.$props.fileData.history) {
                if (this.$props.fileData.history.success && this.$props.fileData.history.has_snapshot && !this.$props.fileData.history.changed) {
                    return this.fileStatusTypes.SAVED_AND_UNCHANGED
                } else if (this.$props.fileData.history.success && this.$props.fileData.history.has_snapshot && this.$props.fileData.history.changed) {
                    return this.fileStatusTypes.SAVED_AND_CHANGED
                } else if (this.$props.fileData.history.success && !this.$props.fileData.history.has_snapshot) {
                    return this.fileStatusTypes.UNSAVED
                }
            }
            return this.fileStatusTypes.STATUS_UNAVAILABLE
        }
    }
}
</script>
