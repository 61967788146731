var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fileData.type !== "folder"
        ? _c(
            "v-menu",
            {
              staticClass: "mb-5 ml-10",
              attrs: {
                "offset-x": "",
                "offset-overflow": "",
                right: "",
                "open-on-hover": "",
                disabled:
                  _vm.currentFileStatus ===
                  _vm.fileStatusTypes.STATUS_UNAVAILABLE,
                "close-delay": _vm.closeDelay
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "d-flex align-center",
                              staticStyle: { "min-width": "200px" }
                            },
                            on
                          ),
                          [
                            _vm.currentFileStatus ===
                            _vm.fileStatusTypes.SAVED_AND_UNCHANGED
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "success", small: "" }
                                  },
                                  [_vm._v("fiber_manual_record")]
                                )
                              : _vm.currentFileStatus ===
                                _vm.fileStatusTypes.SAVED_AND_CHANGED
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "info", small: "" }
                                  },
                                  [_vm._v("fiber_manual_record")]
                                )
                              : _vm.currentFileStatus ===
                                _vm.fileStatusTypes.UNSAVED
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "error", small: "" }
                                  },
                                  [_vm._v("fiber_manual_record")]
                                )
                              : _vm.currentFileStatus ===
                                _vm.fileStatusTypes.STATUS_UNAVAILABLE
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "primary", small: "" }
                                  },
                                  [_vm._v("fiber_manual_record")]
                                )
                              : _vm._e(),
                            _vm.relativeFileModificationDate(
                              _vm.$props.fileData.last_modified_timestamp
                            ) === _vm.$props.fileData.last_modified_timestamp
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateTimeToHuman")(
                                        _vm.$props.fileData
                                          .last_modified_timestamp
                                      )
                                    )
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "primary--text font-weight-bold ml-1",
                                      staticStyle: { "font-size": "10px" }
                                    },
                                    [_vm._v("UTC")]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.relativeFileModificationDate(
                                        _vm.$props.fileData
                                          .last_modified_timestamp
                                      )
                                    )
                                  )
                                ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2697247238
              )
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _vm.currentFileStatus ===
                      _vm.fileStatusTypes.SAVED_AND_UNCHANGED
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-0 primary--text",
                              attrs: {
                                color: "success",
                                border: "left",
                                "colored-border": "",
                                icon: "mdi-check-circle",
                                "max-width": "350"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Unmodified since last snapshot")]
                                  ),
                                  _c("span", { staticClass: "subtitle-2" }, [
                                    _vm._v(
                                      "This file was snapshotted, and no modifications were made since the last snapshot."
                                    )
                                  ]),
                                  _c("v-divider", {
                                    staticClass: "mt-1",
                                    staticStyle: { opacity: "0.22" },
                                    attrs: { color: "success" }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                          attrs: {
                                            text: "",
                                            small: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showFileVersions(
                                                _vm.$props.fileData.fid,
                                                _vm.$props.fileData.short_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-file-compare")]
                                          ),
                                          _vm._v("compare previous versions ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm.currentFileStatus ===
                          _vm.fileStatusTypes.SAVED_AND_CHANGED
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-0 primary--text",
                              attrs: {
                                color: "info",
                                border: "left",
                                "colored-border": "",
                                icon: "info",
                                "max-width": "350"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Modified since last snapshot")]
                                  ),
                                  _c("span", { staticClass: "subtitle-2" }, [
                                    _vm._v(
                                      "This file was snapshotted, but it has been changed since the last snapshot."
                                    )
                                  ]),
                                  _c("v-divider", {
                                    staticClass: "mt-1",
                                    staticStyle: { opacity: "0.22" },
                                    attrs: { color: "secondary" }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-wrap justify-end"
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                          attrs: {
                                            text: "",
                                            small: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showFileVersions(
                                                _vm.$props.fileData.fid,
                                                _vm.$props.fileData.short_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-file-compare")]
                                          ),
                                          _vm._v("compare previous versions ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                          attrs: {
                                            text: "",
                                            small: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addSnapshot(
                                                _vm.snapshotTypes.QUICK_SNAPSHOT
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-camera-plus-outline")]
                                          ),
                                          _vm._v("quick snapshot ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm.currentFileStatus === _vm.fileStatusTypes.UNSAVED
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "ma-0 primary--text",
                              attrs: {
                                color: "error",
                                border: "left",
                                "colored-border": "",
                                icon: "error",
                                "max-width": "350"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Unsnapshotted file")]
                                  ),
                                  _c("span", { staticClass: "subtitle-2" }, [
                                    _vm._v(
                                      "This file hasn't been snapshotted yet."
                                    )
                                  ]),
                                  _c("v-divider", {
                                    staticClass: "mt-1",
                                    staticStyle: { opacity: "0.22" },
                                    attrs: { color: "error" }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addSnapshot(
                                                _vm.snapshotTypes.QUICK_SNAPSHOT
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-camera-plus-outline")]
                                          ),
                                          _vm._v("quick snapshot ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "d-flex align-center",
              staticStyle: { "min-width": "200px" }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: { color: "transparent", small: "" }
                },
                [_vm._v("fiber_manual_record")]
              ),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("dateTimeToHuman")(
                      _vm.$props.fileData.last_modified_timestamp
                    )
                  )
                ),
                _c(
                  "span",
                  {
                    staticClass: "primary--text font-weight-bold ml-1",
                    staticStyle: { "font-size": "10px" }
                  },
                  [_vm._v("UTC")]
                )
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }